@charset "UTF-8";
@import "../symbols";

.Modal {
  @at-root {

    & {
      position: fixed;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: #000;
    }

    .Modal__inner {
      position: absolute;
      top: 16px;
      right: 16px;
      bottom: 16px;
      left: 16px;
      display: flex;
      flex-flow: column;
    }

    .Modal__header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #fff;
    }

    .Modal__heading {
      flex-grow: 1;
      overflow: hidden;
      padding-right: 16px;
      padding-left: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px;
    }

    .Modal__close {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      padding: 0;
      color: inherit;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    .Modal__closeIcon {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }

    .Modal__body {
      flex: 1;
      overflow: auto;
      width: 100%;
      background-color: rgba(#fff, 0.9);
      -webkit-overflow-scrolling: touch;
    }

    // ------------------------------------------------------
    // Transitions
    //

    &.v-enter-active {
      transition: opacity .3s ease-out;
    }

    &.v-enter {
      opacity: 0;
    }

  }
}
