@charset "UTF-8";
@import "../symbols";

.ThumbnailList {
  @at-root {

    & {
      overflow: hidden;
      height: 100%;
      padding-right: constant(safe-area-inset-right);
      padding-right: env(safe-area-inset-right);
      padding-left: constant(safe-area-inset-left);
      padding-left: env(safe-area-inset-left);
      background-color: #000;
      user-select: none;
    }

    .ThumbnailList__list {
      will-change: transform;
      transform: translateZ(0)
    }

    .ThumbnailList__group {
      will-change: transform;
      transform: translateZ(0)
    }

  }
}
