@charset "UTF-8";

.DetailPhoto {
  @at-root {

    & {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .DetailPhoto__inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }

    .DetailPhoto__image {
      display: block;
      width: auto;
      height: auto;
      max-width: none;
      max-height: none;
      margin: auto;
    }

    // ------------------------------------------------------
    // Transition
    //

    .DetailPhoto__inner.v-enter-active,
    .DetailPhoto__inner.v-leave-active {
      transition: opacity 1.5s;
    }

    .DetailPhoto__inner.v-enter,
    .DetailPhoto__inner.v-leave-to {
      opacity: 0;
    }

  }
}
