@charset "UTF-8";

.RadialProgress {
  @at-root {

    & {
      transform: rotate(-90deg)
    }

  }
}
