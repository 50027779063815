@charset "UTF-8";
@import "../symbols";

%StaticPage {
  @at-root {

    // ------------------------------------------------------
    // Phone
    //

    @media #{$mq-lte-tablet} {
      & {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: 40px 16px 72px 16px;
        padding: calc(40px + constant(safe-area-inset-top)) calc(16px + constant(safe-area-inset-right)) 72px calc(16px + constant(safe-area-inset-left));
        padding: calc(40px + env(safe-area-inset-top)) calc(16px + env(safe-area-inset-right)) 72px calc(16px + env(safe-area-inset-left));
        color: #fff;
      }

      &.v-enter-active,
      &.v-leave-active {
        z-index: 1;
        transition: opacity .5s;

        %StaticPage__inner {
          z-index: 1;
          transition: opacity .5s .5s;
        }
      }

      &.v-enter,
      &.v-leave-to {
        opacity: 0;

        %StaticPage__inner {
          opacity: 0;
        }
      }

      %StaticPage__inner {
        color: currentColor;
      }

      %StaticPage__title {
        position: relative;
        color: currentColor;
      }

      %StaticPage__titleImage {
        display: block;
        fill: currentColor;

        &.-pc {
          display: none;
        }
      }

      %StaticPage__closeButton {
        button {
          position: fixed;
          top: 40px;
          top: calc(40px + constant(safe-area-inset-top));
          top: calc(40px + env(safe-area-inset-top));
          right: 0;
          right: constant(safe-area-inset-right);
          right: env(safe-area-inset-right);
          width: 48px;
          height: 48px;
          padding: 0;
          color: #000;
          background-color: #fff;
          border: none;
          cursor: pointer;

          &:hover {
            @include ButtonFlick;
          }
        }

        svg {
          width: 24px;
          height: 24px;
          fill: currentColor;
        }
      }
    }

    // ------------------------------------------------------
    // Desktop
    //

    @media #{$mq-desktop} {
      & {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: 56px 32px;
        color: #fff;
      }

      &.v-enter-active,
      &.v-leave-active {
        z-index: 1;
        transition: opacity .5s;

        %StaticPage__inner {
          z-index: 1;
          transition: opacity .5s .5s;
        }
      }

      &.v-enter,
      &.v-leave-to {
        opacity: 0;

        %StaticPage__inner {
          opacity: 0;
        }
      }

      %StaticPage__inner {
        color: currentColor;
      }

      %StaticPage__title {
        position: relative;
        color: currentColor;
      }

      %StaticPage__titleImage {
        display: block;
        fill: currentColor;

        &.-sp {
          display: none;
        }
      }

      %StaticPage__closeButton {
        button {
          position: fixed;
          top: 64px;
          right: 32px;
          width: 48px;
          height: 48px;
          padding: 0;
          color: #fff;
          background-color: transparent;
          border: none;
          cursor: pointer;

          &:hover {
            @include ButtonFlick;
          }
        }

        svg {
          width: 24px;
          height: 24px;
          fill: currentColor;
        }
      }
    }

  }
}
