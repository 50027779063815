@charset "UTF-8";

.TagOverview {
  @at-root {

    // ------------------------------------------------------
    // Phone
    //

    @media #{$mq-lte-tablet} {
      & {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        padding: 0;
        padding: 0 constant(safe-area-inset-right) 0 constant(safe-area-inset-left);
        padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
        background-color: rgba(#000, 0.76);
      }

      .TagOverview__inner {
        display: flex;
        align-items: flex-start;
      }

      .TagOverview__button {
        flex-shrink: 0;
        margin: 0;
        padding: 0;
        color: inherit;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }

      .TagOverview__icon {
        display: block;
        margin: 12px;
      }

      .TagOverview__svg {
        display: block;
        width: 24px;
        height: 24px;
        fill: currentColor;
      }

      .TagOverview__tags {
        overflow-x: scroll;
        flex-shrink: 1;
        padding-right: 16px;
        font-size: 12px;
        line-height: 48px;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
    }

    // ------------------------------------------------------
    // Desktop
    //

    @media #{$mq-desktop} {
      & {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        max-width: 100%;
        height: 48px + 16px + 16px;
        padding: 16px;
      }

      .TagOverview__inner {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .TagOverview__button {
        flex-shrink: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        text-align: left;
        cursor: pointer;
        appearance: none;

        &:hover .TagOverview__svg {
          @include ButtonFlick;
        }
      }

      .TagOverview__icon {
        display: block;
        width: 48px;
        height: 48px;
        padding: 12px;
        background-color: #fff;
        border-radius: 51%;
      }

      .TagOverview__svg {
        width: 24px;
        height: 24px;
        fill: #3d3d3d;
      }

      .TagOverview__tags {
        z-index: -1;
        overflow: hidden;
        margin-bottom: 0 !important;
        margin-left: 16px;
        padding: 8px;
        background-color: rgba(#000, 0.76);
        font-size: 12px;
        line-height: 1;
        white-space: nowrap;
      }
    }

    // ------------------------------------------------------
    // Transitions
    //

    &.v-enter-active {
      transition: transform 0.6s ease-out 0.4s;
    }

    &.v-enter {
      transform: translateY(100%);
    }

  }
}
