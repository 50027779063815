@charset "UTF-8";

.TagSelector {
  @at-root {

    & {
      position: absolute;
      z-index: 1;
      top: 0;
      top: constant(safe-area-inset-top);
      top: env(safe-area-inset-top);
      right: 0;
      right: constant(safe-area-inset-right);
      right: env(safe-area-inset-right);
      bottom: 0;
      left: 0;
      left: constant(safe-area-inset-left);
      left: env(safe-area-inset-left);
    }

    .TagSelector__inner {
      position: absolute;
      display: flex;
      flex-flow: column;
    }

    .TagSelector__header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: 100%;
      background-color: rgba(#000, 0.9);
    }

    .TagSelector__heading {
      flex-grow: 1;
      overflow: hidden;
      padding-right: 16px;
      padding-left: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px;
    }

    .TagSelector__close {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      padding: 0;
      color: inherit;
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:hover {
        @include ButtonFlick;
      }
    }

    .TagSelector__closeIcon {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }

    .TagSelector__body {
      flex: 1;
      overflow: auto;
      width: 100%;
      background-color: rgba(#000, 0.76);
      -webkit-overflow-scrolling: touch;
    }

    .TagSelector__list {
    }

    .TagSelector__item {
    }

    .TagSelector__label {
      position: relative;
      display: block;
      cursor: pointer;

      &:hover {
        background-color: rgba(#fff, 0.1);
      }
    }

    .TagSelector__checkbox {
      position: absolute;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
    }

    .TagSelector__labelText {
      display: block;
      padding-right: 16px;
      padding-left: 48px;
      white-space: nowrap;
      font-size: 12px;
      line-height: 48px;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 18px;
        width: 12px;
        height: 12px;
        margin: auto;
        border: 1px solid #fff;
        border-radius: 2px;
      }
    }

    .TagSelector__checkbox:checked + .TagSelector__labelText {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 21px;
        width: 6px;
        height: 6px;
        margin: auto;
        background-color: #76ff03;
        border-radius: 1px;
      }
    }

    .TagSelector__checkbox:focus + .TagSelector__labelText {
      outline: 1px dotted #fff;
      outline-offset: -1px;
    }

    [data-whatintent="mouse"] *.TagSelector__checkbox:focus + .TagSelector__labelText {
      outline: none;
    }

    .TagSelector__footer {
      flex: 0;
      width: 100%;
      background-color: rgba(#000, 0.9);
    }

    .TagSelector__reset {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      padding: 0;
      color: inherit;
      background-color: transparent;
      border: none;
      text-align: left;
      cursor: pointer;
    }

    .TagSelector__resetIcon {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin: 12px;
      fill: currentColor;
    }

    .TagSelector__reset:hover .TagSelector__resetIcon {
      @include ButtonFlick;
    }

    // ------------------------------------------------------
    // Phone
    //

    @media #{$mq-lte-tablet} {
      .TagSelector__inner {
        top: 16px;
        right: 16px;
        bottom: 16px;
        left: 16px;
      }
    }

    // ------------------------------------------------------
    // Desktop
    //

    @media #{$mq-desktop} {
      & {
        visibility: hidden;
      }

      .TagSelector__inner {
        top: 16px + 144px + 16px;
        right: 16px;
        bottom: 16px;
        left: 16px;
        visibility: visible;
        width: 400px;
      }

      .TagSelector__labelText {
        line-height: 32px;
      }
    }

    // ------------------------------------------------------
    // Transitions
    //

    &.v-enter-active {
      transition: opacity .3s ease-out;
    }

    &.v-enter {
      opacity: 0;
    }

  }
}
