@charset "UTF-8";
@import "_symbols.scss";

html {
  overflow: hidden;
  height: 100%;
  color: #fff;
  background-color: #000;
  font-family: $font-sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
}

body {
  height: 100%;
  overflow: hidden;
}

a {
  color: #fff;
}

[data-whatintent="mouse"] *:focus,
[data-whatintent="touch"] *:focus {
  outline: none !important;
}
