@charset "UTF-8";

// ------------------------------------------------------
// Fonts
//

@font-face {
  font-family: YuGothicM;
  font-weight: 500;
  src: local("Yu Gothic Medium"),
       local("YuGothic Medium");
}

$font-sans-serif: Verdana, sans-serif;
$font-sans-serif-ja: "Hiragino Kaku Gothic ProN", "Yu Gothic Medium", YuGothicM, sans-serif;
$font-serif: Georgia, serif;

// ------------------------------------------------------
// Media Queries

$mq-phone      : "(max-width: 479px)";
$mq-lte-phone-l: "(max-width: 767px)";
$mq-phone-l    : "(min-width: 480px) and (max-width: 767px)";
$mq-gte-phone-l: "(min-width: 480px)";
$mq-lte-tablet : "(max-width: 1024px)";
$mq-tablet     : "(min-width: 768px) and (max-width: 1024px)";
$mq-gte-tablet : "(min-width: 768px)";
$mq-desktop    : "(min-width: 1025px)";
$mq-standalone : "(display-mode: standalone)";
$mq-menu-1line : "(max-height: 380px)";

// ------------------------------------------------------
// Animations
//

@keyframes ButtonFlick {
  0% {
    opacity: .6;
  }
  10% {
    opacity: .6;
  }
  100% {
    opacity: 1;
  }
}

@mixin ButtonFlick() {
  animation-duration: 0.4s;
  animation-name: ButtonFlick;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

// ------------------------------------------------------
// Utilities
//

@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
