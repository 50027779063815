@charset "UTF-8";
@import "../symbols";

.MainHeader {
  @at-root {

    // ------------------------------------------------------
    // Phone
    //

    @media #{$mq-lte-tablet} {
      & {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 2;
        padding: 10px 16px;
        padding: calc(10px + constant(safe-area-inset-top)) calc(16px + constant(safe-area-inset-right)) 10px calc(16px + constant(safe-area-inset-left));
        padding: calc(10px + env(safe-area-inset-top)) calc(16px + env(safe-area-inset-right)) 10px calc(16px + env(safe-area-inset-left));
        color: #fff;
      }

      &.-opened {
        background-color: rgba(#000, 0.76);
      }

      .MainHeader__backdrop {
        display: none;
      }

      &.-opened .MainHeader__backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        display: block;
      }

      .MainHeader__inner {
        display: flex;
        flex-flow: column;
        align-items: stretch;
      }

      .MainHeader__heading {
      }

      .MainHeader__logo {
        display: block;
        width: 254.5px;
        height: 28px;
        fill: currentColor;
      }

      .MainHeader__copyright {
        display: none;
      }

      &.-opened .MainHeader__copyright {
        display: block;
        order: 1;
        margin-top: 10px;
        font-size: 10px;
        line-height: 28px;
      }

      .MainHeader__toggleMenuButton {
        position: absolute;
        top: 0;
        top: constant(safe-area-inset-top);
        top: env(safe-area-inset-top);
        right: 0;
        right: constant(safe-area-inset-right);
        right: env(safe-area-inset-right);
        text-indent: -9999px;
        color: inherit;
        background-color: transparent;
        border: 0;
        width: 48px;
        height: 48px;
        cursor: pointer;

        &:hover {
          @include ButtonFlick;
        }

        &::before,
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 18px;
          height: 1px;
          background-color: currentColor;
          content: "";
          //transition: transform 0.1s ease-out;
        }

        &::before {
          transform: rotate(0deg) translate(0, -4px);
        }

        &::after {
          transform: rotate(0deg) translate(0, 3px);
        }
      }

      &.-opened .MainHeader__toggleMenuButton {
        &::before {
          transform: rotate(45deg) translate(0, 0) scaleX(1.3);
        }

        &::after {
          transform: rotate(-45deg) translate(0, 0) scaleX(1.3);
        }
      }

      .MainHeader__body {
      }

      &:not(.-opened) .MainHeader__body {
        display: none;
      }

      .MainHeader__nav {
        margin-top: 18px;
      }

      .MainHeader__navItem {
        display: block;
        margin-right: -16px;
        margin-left: -16px;
        padding: 16px;

        &:hover,
        &:focus {
          background-color: rgba(#fff, 0.1);
          @include ButtonFlick;
        }
      }

      .MainHeader__navItemSvg {
        display: block;
        fill: currentColor;
      }

      .MainHeader__fullScreenButton {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 48px;
        height: 48px;
        display: none;
        color: inherit;
        border: 0;
        background-color: transparent;
        cursor: pointer;

        &:hover {
          @include ButtonFlick;
        }

        svg {
          display: block;
          width: 18px;
          height: 18px;
          margin: 0 auto;
          fill: currentColor;
        }
      }

      &.-opened .MainHeader__fullScreenButton {
        display: block;
      }

      @media #{$mq-menu-1line} {
        .MainHeader__nav {
          ul {
            display: flex;
            margin-right: -16px;
            margin-left: -16px;
          }
        }

        .MainHeader__navItem {
          min-width: 128px;
          margin-right: 0;
          margin-left: 0;
        }

        .MainHeader__navItemSvg {
          height: 16px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }

    // ------------------------------------------------------
    // Desktop
    //

    @media #{$mq-desktop} {
      & {
        position: fixed;
        z-index: 2;
        top: 16px;
        right: 16px;
        left: 16px;
        padding: 16px 16px 24px;
        color: #fff;
        background-color: rgba(#000, 0.76);
        transition: background-color 0.15s ease-out;
      }

      &.-deactivated {
        background-color: rgba(#000, 0);
        transition: background-color 0.8s ease-in-out;
      }

      .MainHeader__backdrop {
        display: none;
      }

      .MainHeader__inner {
      }

      .MainHeader__heading {
        font-size: 48px;
        line-height: 1.1;
      }

      .MainHeader__logo {
        display: block;
        width: 400px;
        height: 44px;
        fill: currentColor;
      }

      .MainHeader__copyright {
        font-size: 10px;
        line-height: 1;
      }

      .MainHeader__toggleMenuButton {
        display: none;
      }

      .MainHeader__body {
      }

      .MainHeader__nav {
        margin-top: 22px;

        ul {
          display: flex;
          margin-left: -32px;
        }

        li {
          margin-left: 32px;
        }
      }

      .MainHeader__navItem {
        display: block;
        padding: 7px 0;

        &:hover {
          @include ButtonFlick;
        }
      }

      .MainHeader__navItemSvg {
        display: block;
        height: 14px;
        fill: currentColor;
      }

      .MainHeader__fullScreenButton {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 44px;
        height: 44px;
        color: inherit;
        border: 0;
        background-color: transparent;
        cursor: pointer;

        &:hover {
          @include ButtonFlick;
        }

        svg {
          display: block;
          width: 18px;
          height: 18px;
          margin: 0 auto;
          fill: currentColor;
        }
      }
    }

    // ------------------------------------------------------
    // Transitions
    //

    &.v-enter-active {
      transition: transform 0.6s ease-out 0.4s;
    }

    &.v-enter {
      transform: translateY(-100%);
    }

    &.fade-enter-active {
      transition: opacity 1s .5s;
    }

    &.fade-enter {
      opacity: 0;
    }

  }
}
