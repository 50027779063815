@charset "UTF-8";

.ThumbsPage {
  @at-root {

    & {
      position: absolute;
      width: 100%;
      height: 100%;

      .MainHeader {
        background-color: rgba(#000, 0.76);
      }
    }

    .ThumbsPage__body {
      height: 100%;
    }

    // ------------------------------------------------------
    // Transitions
    //

    &.v-enter-active,
    &.v-leave-active {
      z-index: 1;
      transition: opacity .5s;
    }

    &.v-enter,
    &.v-leave-to {
      opacity: 0;
    }

  }
}
