@charset "UTF-8";

.ShinichiSato {
  @at-root {

    & {
      height: 100%;
    }

  }
}
