@charset "UTF-8";
@import "../symbols";

.Thumbnail {
  @at-root {

    & {
      position: absolute;
      cursor: pointer;

      img {
        display: block;
        width: 100%;
        height: 100%;
        color: transparent; // For alt text
        pointer-events: none;
      }
    }

    &.-disabled {
      cursor: default;
    }

    .Thumbnail__inner {
      svg {
        display: block;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
    }

    @media #{$mq-desktop} {
      &:not(.-disabled) {
        &:hover::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          box-shadow: inset 0 0 0 4px #76ff03;
          pointer-events: none;
        }
      }
    }

  }
}
