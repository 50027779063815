@charset "UTF-8";
@import "../symbols";

.BiographyPage {
  @at-root {

    & {
      @extend %StaticPage;
    }

    .BiographyPage__inner {
      @extend %StaticPage__inner;
    }

    .BiographyPage__title {
      @extend %StaticPage__title;
    }

    .BiographyPage__titleImage {
      @extend %StaticPage__titleImage;
    }

    .BiographyPage__closeButton {
      @extend %StaticPage__closeButton;
    }

    // ------------------------------------------------------
    // Phone
    //

    @media #{$mq-lte-tablet} {
      & {
      }

      .BiographyPage__titleImage {
        width: 247px;
        height: 48px;
      }

      .BiographyPage__lang {
        position: absolute;
        top: 102px;
        font-size: 14px;
      }

      .BiographyPage__langItem {
        display: inline;

        &::before {
          content: "/ "
        }

        &:first-child::before {
          content: normal;
        }
      }

      .BiographyPage__langButton {
        display: inline;
        padding: 0;
        background-color: transparent;
        border: none;
        color: inherit;
        cursor: pointer;

        &.-active {
          text-decoration: underline;
        }
      }

      .BiographyPage__body {
        max-width: 600px;
        margin-top: 56px;

        p {
          &:lang(en) {
            margin: 23px 0;
            font-size: 14px;
            line-height: (24px / 14px);
          }

          &:lang(ja) {
            margin: 20px 0;
            font-size: 14px;
            line-height: (26px / 14px);
            font-family: $font-sans-serif-ja;
          }
        }
      }
    }

    // ------------------------------------------------------
    // Desktop
    //

    @media #{$mq-desktop} {
      & {
        &::before {
          content: "";
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 0;
          background: url("/assets/images/static-bg.jpg") fixed 50%;
          background-size: cover;
        }
      }

      .BiographyPage__titleImage {
        width: 340px;
        height: 64px;
      }

      .BiographyPage__lang {
        position: absolute;
        top: 78px;
        left: 32px + 600px;
        font-size: 14px;
        transform: translateX(-100%);
      }

      .BiographyPage__langItem {
        display: inline;

        &::before {
          content: "/ "
        }

        &:first-child::before {
          content: normal;
        }
      }

      .BiographyPage__langButton {
        display: inline;
        padding: 0;
        background-color: transparent;
        border: none;
        color: inherit;
        cursor: pointer;

        &.-active {
          text-decoration: underline;
        }
      }

      .BiographyPage__body {
        position: relative;
        max-width: 600px;
        margin-top: 60px;

        p {
          &:lang(en) {
            margin: 18px 0;
            font-size: 13px;
            line-height: (23px / 13px);
          }

          &:lang(ja) {
            margin: 16px 0;
            font-size: 14px;
            line-height: (24px / 14px);
            font-family: $font-sans-serif-ja;
          }
        }
      }
    }

  }
}
