@charset "UTF-8";

.Page {
  @at-root {

    & {
    }

    &.-statement,
    &.-biography,
    &.-contact {
      overflow-y: auto;

      body {
        overflow: visible;
      }
    }

  }
}
