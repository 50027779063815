@charset "UTF-8";
@import "../symbols";

.ContactPage {
  @at-root {

    & {
      @extend %StaticPage;
    }

    .ContactPage__inner {
      @extend %StaticPage__inner;
    }

    .ContactPage__title {
      @extend %StaticPage__title;
    }

    .ContactPage__titleImage {
      @extend %StaticPage__titleImage;
    }

    .ContactPage__closeButton {
      @extend %StaticPage__closeButton;
    }

    // ------------------------------------------------------
    // Phone
    //

    @media #{$mq-lte-tablet} {
      & {
      }

      .ContactPage__titleImage {
        width: 247px;
        height: 48px;
      }

      .ContactPage__body {
        position: relative;
        max-width: 600px;
        margin-top: 55px;
      }

      .ContactPage__section {
        margin-top: 50px;

        &:lang(ja) {
          font-size: 14px;
          line-height: (26px / 14px);
          font-family: $font-sans-serif-ja
        }

        &:lang(en) {
          font-size: 14px;
          line-height: (24px / 14px);
        }
      }

      .ContactPage__heading {
        margin-bottom: 12px;

        &:lang(ja) {
        }

        &:lang(en) {
          font-size: 16px;
          line-height: (24px / 16px);
          font-weight: bold;
        }
      }

      .ContactPage__address {
        font-style: normal;
      }
    }

    // ------------------------------------------------------
    // Desktop
    //

    @media #{$mq-desktop} {
      & {
        &::before {
          content: "";
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 0;
          background: url("/assets/images/static-bg.jpg") fixed 50%;
          background-size: cover;
        }
      }

      .ContactPage__titleImage {
        width: 340px;
        height: 64px;
      }

      .ContactPage__body {
        position: relative;
        max-width: 600px;
        margin-top: 60px;
      }

      .ContactPage__section {
        margin-top: 34px;

        &:lang(ja) {
          font-size: 14px;
          line-height: (24px / 14px);
          font-family: $font-sans-serif-ja
        }

        &:lang(en) {
          font-size: 13px;
          line-height: (23px / 13px);
        }
      }

      .ContactPage__heading {
        margin-bottom: 11px;

        &:lang(ja) {
        }

        &:lang(en) {
          font-weight: bold;
        }
      }

      .ContactPage__address {
        font-style: normal;
      }
    }

  }
}
