@charset "UTF-8";

.PhotoDetailPage {
  @at-root {

    & {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .PhotoDetailPage__body {
      height: 100%;
    }

    &.v-enter-active,
    &.v-leave-active {
      z-index: 1;
      transition: opacity .5s;
    }

    &.v-enter,
    &.v-leave-to {
      opacity: 0;
    }

  }
}
