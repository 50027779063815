@charset "UTF-8";
@import "../symbols";
@import "./static-page";

.StatementPage {
  @at-root {

    & {
      @extend %StaticPage;
    }

    .StatementPage__inner {
      @extend %StaticPage__inner;
    }

    .StatementPage__title {
      @extend %StaticPage__title;
    }

    .StatementPage__titleImage {
      @extend %StaticPage__titleImage;
    }

    .StatementPage__closeButton {
      @extend %StaticPage__closeButton;
    }

    // ------------------------------------------------------
    // Phone
    //

    @media #{$mq-lte-tablet} {
      & {
        font-family: $font-serif;
      }

      .StatementPage__titleImage {
        width: 247px;
        height: 48px;
      }

      .StatementPage__body {
        position: relative;
        max-width: 600px;
        margin-top: 56px;

        p {
          margin: 20px 0;
          line-height: 1.5;
        }
      }
    }

    // ------------------------------------------------------
    // Desktop
    //

    @media #{$mq-desktop} {
      & {
        font-family: $font-serif;

        &::before {
          content: "";
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 0;
          background: url("/assets/images/statement-bg.jpg") fixed 50%;
          background-size: cover;
        }
      }

      .StatementPage__titleImage {
        width: 340px;
        height: 64px;
      }

      .StatementPage__body {
        position: relative;
        max-width: 600px;
        margin-top: 60px;

        p {
          margin: 20px 0;
          line-height: 1.5;
        }
      }
    }

  }
}
