@charset "UTF-8";

.AboutFullscreen {
  @at-root {

    & {
    }

    .AboutFullscreen__list {
      padding: 0 16px;
    }

    .AboutFullscreen__item {
      display: flex;
      margin: 20px 0;
    }

    .AboutFullscreen__icon {
      flex-shrink: 0;
      width: 70px;
      height: 70px;

      svg {
        width: 70px;
        height: 70px;
      }
    }

    .AboutFullscreen__text {
      flex-grow: 1;
      width: 0;
      margin-left: 16px;
      padding-right: 16px;
      font-size: 14px;
      line-height: (24px / 14px);

      em {
        display: block;
        font-weight: bold;
        font-style: italic;
      }
    }

  }
}
