@charset "UTF-8";

.CanvasThumbnail {
  @at-root {

    & {
      position: absolute;
      cursor: pointer;
    }

    &.-disabled {
      cursor: default;
    }

    .CanvasThumbnail__canvas {
      width: 100%;
      height: 100%;
    }

    @media #{$mq-desktop} {
      &:not(.-disabled):hover::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: inset 0 0 0 4px #76ff03;
      }
    }

  }
}
