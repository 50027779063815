@charset "UTF-8";

.ProgressBar {
  @at-root {

    & {
      position: fixed;
      left: 0;
      height: 2px;
      background-color: #0f0;
      opacity: 1;
      transition: width 0.7s linear;

      @media #{$mq-phone} {
        bottom: 97px;
      }

      @media #{$mq-phone-l}, #{$mq-tablet} {
        bottom: 48px;
      }

      @media #{$mq-desktop} {
        bottom: 0;
      }
    }

    &.fade-enter-active {
      transition: transform 0.2s ease,
                  opacity .2s ease;
    }

    &.fade-leave-active {
      transition: transform 0.2s ease,
                  opacity .5s ease .7s;
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }

  }
}
