@charset "UTF-8";

.PhotoFooter {
  @at-root {

    // ------------------------------------------------------
    // Phone / Tablet
    //

    @media #{$mq-lte-tablet} {
      & {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
      }

      .PhotoFooter__1 {
        display: flex;
        align-items: center;
        overflow: hidden;
        padding-right: constant(safe-area-inset-right);
        padding-right: env(safe-area-inset-right);
        padding-left: constant(safe-area-inset-left);
        padding-left: env(safe-area-inset-left);
        background-color: rgba(#000, 0.76);
      }

      .PhotoFooter__thumb {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;

        &:hover {
          @include ButtonFlick;
        }

        svg {
          width: 16px;
          height: 16px;
          fill: currentColor;
        }
      }

      .PhotoFooter__description {
        flex-shrink: 1;
        overflow-x: scroll;
        padding-right: 16px;
        font-size: 12px;
        line-height: 48px;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }

      .PhotoFooter__controls {
        display: flex;
        justify-content: center;
        margin-top: 1px;
        background-color: rgba(#000, 0.76);
      }

      .PhotoFooter__control {
        position: relative;
        z-index: 0;

        &.-prev {
        }

        &.-play {
          margin-right: 32px;
          margin-left: 32px;
        }

        &.-next {
        }
      }

      .PhotoFooter__controlButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        padding: 0;
        color: inherit;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &:hover {
          @include ButtonFlick;
        }

        &.-disabled {
          opacity: .3;
        }
      }

      .PhotoFooter__controlButtonIcon {
        fill: currentColor;
        width: 24px;
        height: 24px;
      }

      .PhotoFooter__control.-playing .PhotoFooter__progressBg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 32px;
        height: 32px;
        margin: auto;
        background-color: rgba(#fff, 0.2);
        border-radius: 51%;
      }

      .PhotoFooter__progress {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: auto;
      }
    }

    @media #{$mq-phone-l}, #{$mq-tablet} {
      & {
        display: flex;
      }

      .PhotoFooter__1 {
        flex: 1 1 auto;
        width: 0;
        padding-right: 0;
      }

      .PhotoFooter__controls {
        flex: 0 0 auto;
        width: 225px;
        width: calc(225px + constant(safe-area-inset-right));
        width: calc(225px + env(safe-area-inset-right));
        margin-top: 0;
        margin-left: 1px;
        padding-right: constant(safe-area-inset-right);
        padding-right: env(safe-area-inset-right);
      }
    }


    // ------------------------------------------------------
    // Desktop
    //

    @media #{$mq-desktop} {
      & {
        position: fixed;
        right: 16px;
        bottom: 16px;
        left: 16px;
        display: flex;
      }

      .PhotoFooter__1 {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        width: 0;
      }

      .PhotoFooter__thumb {
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        background-color: #fff;
        border-radius: 51%;

        &:hover svg {
          @include ButtonFlick;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .PhotoFooter__description {
        flex-shrink: 1;
        overflow: hidden;
        margin-right: 16px;
        margin-bottom: 0 !important;
        margin-left: 16px;
        padding: 8px;
        background-color: rgba(#000, 0.67);
        font-size: 12px;
        line-height: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .PhotoFooter__controls {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        margin-left: 1px;
      }

      .PhotoFooter__control {
        position: relative;
        left: 4px;
        z-index: 0;

        &.-prev {
        }

        &.-play {
          margin-right: 16px;
          margin-left: 16px;
        }

        &.-next {
        }
      }

      .PhotoFooter__controlButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        padding: 0;
        color: inherit;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &:hover {
          @include ButtonFlick;
        }

        &.-disabled {
          opacity: .3;
        }
      }

      .PhotoFooter__controlButtonIcon {
        fill: currentColor;
        width: 24px;
        height: 24px;
      }

      .PhotoFooter__progress {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: auto;
      }
    }

    // ------------------------------------------------------
    // Transitions
    //

    &.v-enter-active {
      transition: transform 0.6s ease-out 0.4s;
    }

    &.v-enter {
      transform: translateY(100%);
    }

  }
}
